.post-title {

    font-size: 2.2rem;
    margin-bottom:1rem;
    font-weight: 700;

}

p , h3 {
    margin-bottom:1rem; 
}
