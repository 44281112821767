a {
    transition: all .75s ease;
    text-decoration: none;
    color: var(--white);
}

.btn-reverse {
    color: var(--main-color);
    font-weight: bold;
}

a:hover {
    color: var(--main-color);
}

.btn-reverse:hover {
    color: var(--white);
}

.add-cart,
.listen {
    border: solid 1px white;
    color: white;
    padding: 5px 20px;
    background-color: #000000;
    transition: all .75s ease;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}

.sold {
    border: solid 1px white;
    color: white;
    padding: 5px 20px;
    background-color: #000000;
    font-size: 1rem;
    text-transform: uppercase;
    margin-right: 10px;
}

.add-cart {
    margin: 0 10px 15px 0;
}

.add-cart:hover,
.listen:hover {
    background-color: var(--main-color);
}

.listen {
    margin-right: 1rem;
    margin-bottom: 1rem;
}

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-dd {

    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    color: #ffffff;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 0;
    margin: 0 10px 15px 0;
    border: 1px solid #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    cursor: pointer;
}

.select-dd::-ms-expand {
    display: none;
}

.select-dd:hover {
    border-color: #ffffff;
    background: var(--main-color);
    transition: all .75s ease;
}

.select-dd:focus {
    border-color: #ffffff;
    color: #ffffff;
    outline: none;
}

.select-dd option {
    font-weight: normal;
}


.track-list .listen {
    padding: 0;
    background: none;
    border: 0;
    display: inline-block;
    width: 15px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 0;
    vertical-align: middle;
}

.no-bullet {
    list-style: none;
    margin-left: -25px;
    padding-left: 0;
}