.site-header {
    padding:2rem 2rem 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

}

.site-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: 1px;
    

    @media only screen and (max-width: 768px) {
        width: 70%;
        font-size: 1.5rem;
    }
}

.hline {
           
    display:none;
    height: 1px;
    width: 100%;
    background-color: white;

    @media only screen and (min-width: 768px) {
        display: block;
    }
   
}