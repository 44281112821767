


// Import partials.
@import
  "speedy/variables",
  "speedy/typography",
  "speedy/base",
  "speedy/buttons-and-links",
  "speedy/triangle-background",
  "speedy/header",
  "speedy/c-home-news",
  "speedy/navigation",
  "speedy/shopping-cart",
  "speedy/shop",
  "speedy/c-releases-slider",
  "speedy/audio-player",
  "speedy/footer";









