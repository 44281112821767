footer {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  #socials {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    text-align: left;
  }

  .blm {
    max-width:50%;
    text-align: center;
     @media only screen and (max-width: 768px) {
      max-width:100%;
      margin:1rem 0 1rem 0;
      text-align: left;
     }
    
  }

  img {
    max-width: 100px;
  }
}
