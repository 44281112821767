*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html , body {
    margin: 0;
    padding: 0;
    font-family: futura-pt, sans-serif;
    background-color: var(--black);
    color:  var(--white);
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}

.fixed {
    position: fixed;
    width:100%;
}

// Show the content over the nice backgorund div. 
.page-content {
    position: relative;
    padding:2rem 2rem 0 2rem;
}

.wrapper:not(.wrapper--single) {
    
    @media only screen and (min-width: 768px) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50vw 1fr;
    }
}



.shop-inner .wrapper {

    @media only screen and (min-width: 768px) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr;
    }

}

.post {
    width: 100%;
   
    

    @media only screen and (min-width: 768px) {
        width: calc(100% - 4rem);
        grid-column:  2/-1;
        max-width:50vw;
        margin:0;
    }
}

/* Mailchimp overides */

#mc_embed_signup form {
    padding:0 !important;
    margin-top:1rem;
}

#mc_embed_signup .button {
    background-color: var(--main-color)!important;
}

.signup {
    margin: 3rem 0 3rem 0;
    @media only screen and (min-width: 768px) {
        margin: 0 0 3rem 0;
        grid-column: 1/2;
        max-width: 50%;
       
    }
}