.all-tracks {
    height: 65px;
    margin-bottom: 15px;
    margin-top: 10px;
    overflow: auto;
}

.all-tracks li  {
  cursor:pointer;
}

.all-tracks li:hover  {
  color: var(--main-color);
}

.all-tracks::-webkit-scrollbar {
	width: 10px;
	background-color: #464646;
}

.all-tracks::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--main-color);
}
.gradient {
  height:190px;
  border: 1px solid white;

//   -webkit-box-shadow: inset 0 1px 0px rgba(255,255,255,.2);
//   -moz-box-shadow: inset 0 1px 0px rgba(255,255,255,.2);
//   box-shadow: inset 0 1px 0px rgba(255,255,255,.2);
  background: black; /* Old browsers */
// background: -webkit-linear-gradient(top, #494949 0%, #434242 31%, #393838 55%, #242323 83%, #1b1a1a 100%, #161515 100%, #0b0b0b 100%);

//   background: -moz-linear-gradient(top, #494949 0%, #434242 31%, #393838 55%, #242323 83%, #1b1a1a 100%, #161515 100%, #0b0b0b 100%);
//   background: -o-linear-gradient(top, #494949 0%, #434242 31%, #393838 55%, #242323 83%, #1b1a1a 100%, #161515 100%, #0b0b0b 100%);
//   background: -ms-linear-gradient(top, #494949 0%, #434242 31%, #393838 55%, #242323 83%, #1b1a1a 100%, #161515 100%, #0b0b0b 100%);
//   background: linear-gradient(top, #494949 0%, #434242 31%, #393838 55%, #242323 83%, #1b1a1a 100%, #161515 100%, #0b0b0b 100%); /* W3C */
}

.container {
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -o-transition: all .7s ease;
  -ms-transition: all .7s ease;
  transition: all .7s ease;
  position: fixed;
  transform: translateY(100px);
  width: 327px;
  height: 70px;
  bottom:0;
//   -webkit-border-radius: 10px;
//   -moz-border-radius: 10px;
//   border-radius: 10px;

//   -webkit-box-shadow: 0px 0px 13px rgba(0,0,0,.3),inset 0 1px 0px rgba(255,255,255,.2);
//   -moz-box-shadow: 0px 0px 13px rgba(0,0,0,.3),inset 0 1px 0px rgba(255,255,255,.2);
//   box-shadow: 0px 0px 13px rgba(0,0,0,.3),inset 0 1px 0px rgba(255,255,255,.2);
  //bottom: -270px;
  right: 0;
  padding: 10px;
  opacity:0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.js-show-player {
    bottom:0;
    z-index: 10;
    opacity: 100;
    position: fixed;
    transform: translateY(0px);

}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


// .containerLarge {
//   height: 427px;
// }


.player {
    // -webkit-box-sizing: border-box;
    // -moz-box-sizing: border-box;
    // -ms-box-sizing: border-box;
    // box-sizing: border-box;
    position: absolute;
    width: 200px;
    bottom: 10px;
    width: 95%;
 
    // -webkit-border-radius: 3px;
    // -moz-border-radius: 3px;
    // border-radius: 3px;
    padding: 5px 10px 5px 10px;
    display:flex;
    flex-direction:column;
}
 
.player-button {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(../assets/images/sprite.svg);
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left:10px;
    background-size: 175px 25px;
}
 
#play {
    background-position: -50px -1px;
}
 
#pause {
    background-position: -76px -1px;
}
 
#mute {
    background-position: -50px -1px;
}
 
#muted {
    background-position: -50px -1px;
}
 
input[type="range"] {
    width: 285px;
    /* margin-top: -5px; */
    margin: 5px;
}
 
#close {
    float: right;
    background-position: -99px -1px;
    display: none;
}

.js-hidden {
    display:none;
}
 
// .volume {
//     position: absolute;
//     height: 100px;
//     width: 34px;
//     border: 1px solid black;
//     background-color: #242323;
//     top: -97px;
//     display: none;
// }
// input{
//     display:none\9!important;
// }
input[type="range"] {

    // width: 285px;
    // /* margin-top: -5px; */
    // margin: 5px;
    // // -webkit-appearance: none;
    // border: 1px solid black;
    // position: absolute;
    // top: 18px;
    // display: block;
    // width: 63%;
    // height: 15px;
 
    // -webkit-border-radius: 20px;
    // -moz-border-radius: 20px;
    // border-radius: 20px;
    // background-color: #242323;
    // left: 90px;
 
    // -webkit-box-shadow: inset 0px 4px 4px rgba(0,0,0,.6);
    // -moz-box-shadow: inset 0px 4px 4px rgba(0,0,0,.6);
    // box-shadow: inset 0px 4px 4px rgba(0,0,0,.6);
}
 
input::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     width: 20px;
//     height: 20px;
//     border:1px solid black;
 
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     background: #80e4df; /* Old browsers */
// background: -webkit-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
 
//     background: -moz-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
//     background: -o-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
//     background: linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%); /* W3C */
}

input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: var(--main-color);
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--main-color);
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: var(--main-color);
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: var(--main-color);
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: var(--main-color);
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: var(--main-color);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: var(--main-color);
  }