#news {

    width: 100%;
   
    

    @media only screen and (min-width: 768px) {
        width: calc(100% - 5rem);
        grid-column:  1;
        max-width:50vw;
        margin:0 0 0 auto;
    }

}
#news h3 {
    text-align: left;
    border-bottom: solid 1px;
    padding-bottom: 9px;
    font-style: italic;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom:1rem;
}

#news h4 {
    text-align: left;
    padding-top: 1rem;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom:1rem;
}