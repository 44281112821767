.shop-inner .triangle,
.shop-inner .t-line {
  display: none;
}

.compilations,
.singles,
.merch {
  h3 {
    font-size: 2.5rem;
    // border-bottom: solid 1px #ffffff;
    //@debugmargin-bottom:2rem;
  }

  .product-info h4 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .product-info ul {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
}

.comp-list {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .product-info {
    display: grid;
    grid-template-columns: minmax(150px, 300px) 2fr;
    grid-gap: 2rem;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
}

.single-list {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
  }
}

.album-list {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .track-list {
    margin-left: 2rem;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
  }
}

.boxheader {
  grid-column: 1 / -1;

}

.image-grid {
  display: flex;
  align-items: flex-end;
  gap: 0 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 2rem;
  scroll-snap-type: x mandatory;
  grid-column: 1 / -1;

  img {
    max-width: 400px;
  }

  @media only screen and (max-width: 768px) {
    img {
      max-width: 300px;
    }
  }

}

.boxset {
  margin: 0 0 3rem 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  h4,
  hr {
    margin-bottom: 1rem;

  }
}

.merch-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .product {
    display: grid;
    grid-template-columns: minmax(150px, 300px) 2fr;
    grid-gap: 2rem;

    img {
      max-width: 100%;
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.shop-home {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 40vw 1fr;
  grid-template-rows: 100px 1fr 1fr;
  min-height: 50vh;
}

.shop-nav {
  margin-bottom: 2rem;
}

.shop-cats {
  z-index: 2;
  transition: all 0.75s ease;
  grid-column: 1;
  list-style: none;
  font-size: calc(20px + 4vw);
  line-height: calc(20px + 4vw);
  font-weight: 700;
  text-transform: uppercase;

  @media only screen and (max-width: 768px) {
    .shop-cats-item {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (min-width: 768px) {
    grid-column: 2;
    // transform: rotate(45deg);
    text-align: right;
    transform-origin: top left;
    transform-style: preserve-3D;
  }
}

.single-list+hr {
  margin: 4rem 0;
}

iframe+ul {
  display: none;
}