#releases {

    width: 100%;
   
    

    @media only screen and (min-width: 768px) {
        width: calc(100% - 5rem);
        grid-column:  2/-1;
        max-width:50vw;
        margin:0;
    }

}
#releases h3 {
    text-align: right;
    border-bottom: solid 1px;
    padding-bottom: 9px;
    font-style: italic;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom:1rem;
}

#releases ul {
    margin: 0 0 10px 0;
    padding: 0 0 1rem 0;
    list-style: none;
    @media only screen and (min-width: 768px) {
        // overflow: scroll;
        // max-height: 45vh;
        z-index:10;
    }
    
}

#releases li {
    display: flex;
    flex-direction: row;
    align-content:flex-start;
}

.product-info img {
    max-width: 100%;
    margin: 0 auto 1rem auto;
}


.product-info h4{
    margin-bottom:20px;
    text-transform: uppercase;
    font-size: 2.25rem;

    @media only screen and (max-width: 768px) {
        font-size: 1.75rem;
        line-height: 26px;
    }

}



.slide-buttons{
    padding: 0rem 0 2rem 0;
    display: flex;
    justify-content: flex-end;
}

.prev-arrow{
    transition: all .75s ease;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 15px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    cursor: pointer;
}

.prev-arrow:hover  {
    border-right: 15px solid #f7bb00; 
}
.next-arrow{
    transition: all .75s ease;
    margin-left:30px;
    width: 0;
    height: 0;
    border-right: 0 solid transparent;
    border-left: 15px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    cursor: pointer;
}

.next-arrow:hover  {
    border-left: 15px solid var(--main-color); 
}

.track-list {
    margin-bottom:10px;
}

.slick-slide {
    padding:5px 5px 10px 5px;
}