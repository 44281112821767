



nav {
    display:none;
    transition: background-color .5s ease;
}

@media only screen and (min-width: 768px) {
    nav {
        display:block;
        
    }
}

.mobile-menu-on {
    display:block;
    position: absolute;
    width:100%;
    height: 100vh;
    overflow-y: hidden;
    background-color:var(--main-color);
    display:flex;
    justify-content: center;
    align-content: center;
    z-index: 10;
    top:0;
    left:0;
}

nav ul {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @media only screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: flex-end;
        flex-wrap: wrap;
    }
   
}

nav li {

    @media only screen and (max-width: 768px) {
        text-align: center;
        font-size:2rem;
        background-color:var(--text-color);
    }
    display:flex;
    align-items: center;
    padding:5px 10px;
    margin-bottom:10px;
    
}

nav li:last-of-type {
    padding-right:0;
}



nav li.active {
    border-top:solid 1px ;
    color:var(--main-color);
}

nav li.active a {
    color:var(--main-color);
}



.hamburger {
    padding: 15px 10px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; 
    z-index: 10;
    position: absolute;
    right: 30px;
    top: 20px;
}

@media only screen and (min-width: 768px) {

    .hamburger {
        display:none;
    }
    
}

.hamburger:hover {
    opacity: 0.7; 
}

.hamburger:focus {
    outline: none;
    border: dashed 2px var(--white); 
}

.hamburger.is-active:hover {
    opacity: 0.7; 
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color:  var(--white);
    z-index: 10;
}
  
.hamburger-box {
    width: 42px;
    height: 42px;
    display: inline-block;
    position: relative;
    background-color: black;
}
  
.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; 
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color:  var(--white);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; 
}
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; 
}

.hamburger-inner::before {
    top: -10px; 
}

.hamburger-inner::after {
    bottom: -10px;
}

/* Spring Effect */

.hamburger--spring .hamburger-inner {
    top: 10px;
    transition: background-color 0s 0.13s linear; 
}

.hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--spring.is-active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important; 
}

.hamburger--spring.is-active .hamburger-inner::before {
      top: 0;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(45deg); 
}

.hamburger--spring.is-active .hamburger-inner::after {
      top: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(-45deg); 
}